<template>
    <div>
        <h1>Demandes ouvertures</h1>
        <a-row type="flex" justify="space-between" class="table_head">
            <a-col :xs="24" :sm="4" class="mrgin_btm">

            </a-col>
            <a-col :xs="24" :sm="4" class="mrgin_btm">
                <search-component @change="search($event)"/>
            </a-col>
        </a-row>

        <a-row type="flex" justify="center">
            <a-col :span="24">
                <ListDemandesOuvertures
                        :data-source="demandes"
                        :processing="processing"
                        :display-decision-actions="false"
                        :is-admin="true"
                        @edit="onEdit($event)"
                        @delete="onDelete($event)"
                />
            </a-col>
        </a-row>
    </div>
</template>
<script>
    import ListDemandesOuvertures from "@/components/common/ListDemandesOuvertures";
    import SearchComponent from "@/components/common/Search";
    import {mapActions, mapState} from "vuex";
    import _ from "lodash";

    export default {
        Name: "DemandesIndex",
        components: {
            ListDemandesOuvertures,
            SearchComponent,
        },
        created() {
            this.fetchData();
        },
        data() {
            this.search = _.debounce(this.search, 750);
            return {
                loading: false,
                visible: false,
                processing: true,
                selectedDemande: null,
                newDemandeModalKey : 0
            };
        },

        computed: {
            ...mapState({
                demandes: (state) => state.demandes.demandes,
            }),
        },
        methods: {
            closeModal() {
                this.selectedDemande = null
                this.visible = false;
            },
            showModal() {
                this.genererateNewDemandeModalKey();
                this.visible = true;
            },
            genererateNewDemandeModalKey() {
                this.newDemandeModalKey = this.getRandomInt();
            },
            onDelete(id) {
                this.delete(id)
                    .then(() =>this.fetchData());
            },
            onEdit(id) {
                this.edit(id)
                    .then((response) => {
                        this.selectedDemande = response.data;
                        this.showModal();
                    });
            },
            handleCreate() {
                const form = this.$refs.DemandeForm.form;
                let that = this;
                form.validateFields((err, values) => {
                    if (!err) {
                        this.startLoading();
                        if(this.selectedDemande){
                            this.update({ id : this.selectedDemande.id, ...values})
                                .then((response) => {
                                    if (response.status === that.HTTP_OK) {
                                        that.closeModal();
                                        form.resetFields();
                                        that.fetchData();
                                    }
                                })
                                .finally(() => that.stopLoading());
                            return
                        }
                        this.add({...values})
                            .then((response) => {
                                if (response.status === that.HTTP_OK) {
                                    that.closeModal();
                                    form.resetFields();
                                    that.fetchData();
                                }

                            })
                            .finally(() => that.stopLoading());
                    }
                });
            },
            fetchData(q = '') {
                this.startProcessing();
                this.getDemandes({'q': q})
                    .finally(() => this.stopProcessing())
            }
            ,
            startProcessing() {
                this.processing = true;
            },
            search(value) {
                this.fetchData(value)
            },
            stopProcessing() {
                this.processing = false
            },
            startLoading() {
                this.loading = true;
            },
            stopLoading() {
                this.loading = false
            },
            ...mapActions({
                getDemandes: "fetchDemandes",
                add: "storeDemandes",
                edit: "fetchDemandeById",
                delete: "destroyDemande",
                update: "updateDemande",
            }),
        },
    };
</script>

<style scoped>
    .mrgin_btm {
        margin-bottom: 3%;
    }
</style>