var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Demandes ouvertures")]),
      _c(
        "a-row",
        {
          staticClass: "table_head",
          attrs: { type: "flex", justify: "space-between" }
        },
        [
          _c("a-col", { staticClass: "mrgin_btm", attrs: { xs: 24, sm: 4 } }),
          _c(
            "a-col",
            { staticClass: "mrgin_btm", attrs: { xs: 24, sm: 4 } },
            [
              _c("search-component", {
                on: {
                  change: function($event) {
                    return _vm.search($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("ListDemandesOuvertures", {
                attrs: {
                  "data-source": _vm.demandes,
                  processing: _vm.processing,
                  "display-decision-actions": false,
                  "is-admin": true
                },
                on: {
                  edit: function($event) {
                    return _vm.onEdit($event)
                  },
                  delete: function($event) {
                    return _vm.onDelete($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }